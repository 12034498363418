export function getQueryParams() {
    function identity(e) {
        return e;
    }
    function toKeyValue(params, param) {
        const [key, value] = param.split("=");

        params[key] = params[key] ? [value].concat(params[key]) : value;
        return params;
    }

    return decodeURIComponent(window.location.search).replace(/^\?/, "").split("&").filter(identity).reduce(toKeyValue, {});
}
