const PhotoLab = {
    calculateTotalPrice: state => {
        const totalPhotosPrice = state.photos
            .map(photo => {
                const totalPhoto = photo.formats.reduce((a, b) => a + b.count * b.price_per_one, 0);
                return totalPhoto;
            })
            .reduce((a, b) => a + b, 0);
        return totalPhotosPrice;
    },
};
export default PhotoLab;
