/**
 * A simple forEach() implementation for Arrays, Objects and NodeLists
 * @private
 * @param {Array|Object|NodeList} collection Collection of items to iterate
 * @param {Function} callback Callback function for each iteration
 * @param {Array|Object|NodeList} scope Object/NodeList/Array that forEach is iterating over (aka `this`)
 * @return {Array|Object}
 */
var forEach = function(collection, callback, scope = null) {
    const result = [];
    if (collection === null) {
        return result;
    }
    if (Object.prototype.toString.call(collection) === "[object Object]") {
        for (var prop in collection) {
            if (Object.prototype.hasOwnProperty.call(collection, prop)) {
                result.push(callback.call(scope, collection[prop], prop, collection));
            }
        }
    } else {
        for (var i = 0, len = collection.length; i < len; i++) {
            result.push(callback.call(scope, collection[i], i, collection));
        }
    }
    return result;
};
export default forEach;
