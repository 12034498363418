import React from "react";
import Icon from "./Icon";

const Button = props => {
    return (
        <button
            onClick={props.onClick}
            ref={props.ref}
            disabled={props.disabled}
            type={props.htmlType || "button"}
            className={["Button", props.type].join(" ")}
        >
            {props.children}
            {props.icon && <Icon type={props.icon} />}
        </button>
    );
};

export default Button;
