const Url = {
    serialize: function(obj, prefix) {
        var str = [];
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                const k = prefix ? prefix + "[" + p + "]" : p;
                const v = obj[p];
                str.push(
                    v !== null && typeof v === "object" && Object.keys(v).length > 0 ? Url.serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v)
                );
            }
        }
        return str.join("&");
    },
};

export default Url;
