import Icon from "components/Icon";
import ClassLoginForm from "components/Forms/ClassLoginForm";
import React from "react";
import "./Shop.scss";

const ShopNotReady = props => {
    return (
        <div className="Login">
            <div className="container">
                <header>
                    <a href="/" className="logo">
                        <img src="/img/skolyfrontlogo.svg" alt="" />
                    </a>
                    <a href="/" className="blue-button">
                        <Icon type="ArrowBackIcon" /> Zpět na hlavní stránku
                    </a>
                </header>
                <ClassLoginForm />
                <footer>
                    <span className="copyright">FLATE Group s.r.o. | ŠkolyFoto.cz</span>
                </footer>
            </div>
        </div>
    );
};

export default ShopNotReady;
