import axios from "helpers/axios";
import AxiosCommon from "helpers/axios/AxiosCommon";
import graphql from "helpers/graphql";
import Url from "helpers/Url";
import Queries from "helpers/Queries";

class Api {
    get = (url, config) => {
        if (!config) {
            config = {};
        }
        return axios
            .get(url, {
                ...config,
            })
            .then(r => r.data);
    };
    getNoCache = (url, config) => {
        if (!config) {
            config = {};
        }
        return axios
            .get(url, {
                cache: {
                    maxAge: -1,
                },
                ...config,
            })
            .then(r => r.data);
    };
    post = (url, data, config) => {
        if (!config) {
            config = {};
        }
        return axios
            .post(url, data, {
                cache: {
                    maxAge: 0,
                },
                ...config,
            })
            .then(r => r.data);
    };

    markAsPaid = shopOrder => {
        return this.post(`admin/orders/${shopOrder.order_id}/class/${shopOrder.order_class_id}/shop-order/${shopOrder.id}/paid`);
    };
    markAsCancelled = shopOrder => {
        return this.post(`admin/orders/${shopOrder.order_id}/class/${shopOrder.order_class_id}/shop-order/${shopOrder.id}/cancelled`);
    };
    setSorted = (shop_order_id, value) => {
        return this.get(`admin/shop-orders/${shop_order_id}/sorted/${value ? 1 : 0}`);
    };
    postTicket = (shopOrder, data) => {
        return this.post("admin/orders/" + shopOrder.order_id + "/class/" + shopOrder.order_class_id + "/shop-order/" + shopOrder.id + "/ticket", data);
    };
    getPrices = () => {
        return this.get("shop/prices", {noCamelCase: true});
    };
    getIp = () => {
        return this.get("shop/ip", {noCamelCase: true});
    };

    fetchShop = (id = null) => this.getNoCache("shop" + (id ? "/" + id : ""), {noCamelCase: true});
    fetchOrder = (id, hash) => {
        return this.get("/order/" + id);
    };
    loginToShop = password => this.post("shop/login", {password: password});
    loginToOrder = password => this.post("order/login", {password: password});

    search = (query, more) => this.post("/admin/search", {query, ...more});
    loginToAdmin = data => this.post("/admin/user/login", data);
    logout = () => this.post("/admin/user/logout");
    setPayoutCompleted = (id, value) => this.post("/admin/users/payouts/" + id + "/paid", {completed: value});
    getExports = (type, filters) => this.get("admin/exports" + (type && "/" + type) + "?" + Url.serialize(filters));
    deleteExport = exportDetail => {
        return axios.delete("/admin/exports/" + exportDetail.id).then(r => AxiosCommon.toCamelCaseOld(r.data));
    };
    confirmExportReceived = exportDetail => {
        return axios.post("/admin/exports/" + exportDetail.id, {action: "received"}).then(r => r.data);
    };
    confirmExportShipped = exportDetail => {
        return axios.post("/admin/exports/" + exportDetail.id, {action: "shipped"}).then(r => r.data);
    };
    moveExportToMaster = (exportDetail, transform) => {
        return axios.post("/admin/exports/" + exportDetail.id, {
            action: "move",
            toMaster: true,
            entity: "export",
            transform,
        });
    };
    getNotifications = () => this.getNoCache("admin/user/notifications");
    setNotificationsRead = () => this.post("admin/user/notifications/read");

    postPayout = (uid, data) => this.post("/admin/users/" + uid + "/payout", data);
    postOrderTrip = (orderId, data) => this.post("/admin/orders/" + orderId + "/trip", data);
    postOrderVouchers = (orderId, data) => this.post("/admin/orders/" + orderId + "/vouchers", data);
    getUsers = () => this.get("admin/users");
    getOrders = ordersQuery => {
        return graphql.query(ordersQuery).then(result => AxiosCommon.toCamelCase(result.data));
    };
    /*getTickets = () => {
        return axios
            .get("/admin/tickets")
            .then(r => {
                Relationships.trySetRelationshipsFromResponse(r);
                return r;
            })
            .then(r => r.data);
    };*/

    confirmPrintJob = print => this.post("admin/prints/" + print.id + "/confirm");
    confirmPrintJobReceived = print => this.post("admin/prints/" + print.id + "/confirm-received");
    cancelPrintJob = print => this.post("admin/prints/" + print.id + "/cancel");
    exportPrintJob = print => this.post("admin/prints/" + print.id + "/export");
    getExport = id => this.get("/admin/exports/" + id);

    fetchPayment = (shop_order_id, reference) => {
        return this.get("/payments/" + shop_order_id + "/" + reference);
    };

    initPayment = (payment, hash) => {
        return this.post("/payments/pay-stripe/" + payment.id + "/" + hash).then(r => {
            if (r.redirect) {
                window.location.href = r.redirect;
            } else {
            }
            return r;
        });
    };

    allowOrdering = (orderId, allow) => {
        return this.post("/admin/orders/" + orderId + "/allow-ordering", {value: allow ? 1 : 0});
    };

    paidWithPayPal = (payment, hash, details, data) => {
        return this.post("/payments/paypal/" + payment.id + "/" + hash, {details, data}).then(r => {
            return r;
        });
    };

    getAllBranchesForPersonalPickupDelivery = zip => {
        if (!zip) {
            return [];
        }

        return this.post("/shop/destination-branches", {zip: zip}).then(r => {
            return r;
        });
    };

    getAnalytics = () => {
        return this.get("/admin/analytics").then(r => {
            return r;
        });
    };
    flushServerCache = () => {
        return this.get("/admin/flush-cache").then(r => {
            return r;
        });
    };

    getOrder = orderId => {
        return graphql.query(Queries.Order(orderId)).then(result => AxiosCommon.toCamelCase(result.data));

        // this.get("admin/orders/" + orderId);
    };

    getLead = leadId => this.get("leads/" + leadId);
    getPrint = id => this.get("admin/prints/" + id);

    getPrints = () => this.get("admin/prints");
    checkVoucher = code => this.post("shop/check-voucher", {code: code});
    sendTicketMessage = (ticket, data) => this.post("admin/tickets/" + ticket.id, data);

    reportRightClick = props => {
        return this.post("shop/report-right-click", props, {
            loader: false,
        });
    };

    submitComplaint(data) {
        return this.post("complaint/report", data, {});
    }

    getCharts = type => this.post("admin/analytics/chart", type);
}

export default new Api();
