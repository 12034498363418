import React, {useEffect} from "react";
import {createField, fieldPresets} from "react-advanced-form";

import ReactTooltip from "react-tooltip";
import styled, {css, keyframes} from "styled-components";
import feather from "feather-icons";

export const slideDown = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`;

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

export const loadStripe = keyframes`
  0% { width: 0; }
  10% { width: 10%; }
  20% { width: 45%; }
  36% { width: 60%; }
  90% { width: 85%; }
  100% { width: 95%; }
`;

export const finishAndVanish = keyframes`
  50% { width: 100%; }
  100% { height: 0; }
`;
export const defaultOptions = {
    height: 16,
    width: 16,
};

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({animated}) =>
        animated &&
        css`
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: ${dash} 8s ease forwards;
        `}
`;

function Icon({name, animated, ...options}) {
    const svg = feather.icons[name].toSvg(options);

    return <IconContainer animated={animated} data-tip={options.hint || undefined} dangerouslySetInnerHTML={{__html: svg}} />;
}

const ValidationStatus = styled.div`
    display: ${({valid, invalid}) => (valid || invalid ? "flex" : "none")};
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    &:before {
        position: absolute;
        content: "";
        height: 23px;
        width: 1px;
        background-color: ${({valid, invalid}) => (valid && "rgba(0, 191, 97, .25)") || (invalid && "rgba(255, 0, 0, .25)")};
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transform-origin: center;
    }
`;

//animation: ${animations.slideDown} .2s ease;
const FormInput = props => {
    const {innerRef, name, overrideFieldProps, label, value, hint, disabled, fieldProps, fieldState} = props;
    const {required} = fieldProps;
    const {validating, validatedAsync, valid, invalid, errors} = fieldState;
    let style = {};
    if (invalid || valid) {
        style = {borderBottom: invalid ? "1px solid red" : "1px solid green"};
    }
    useEffect(() => {
        if (props.onValidChanged) {
            props.onValidChanged(name, valid, invalid);
        }
    }, [name, valid, invalid, props.onValidChanged]);

    let InputComponent = "input";
    if (props.type === "textarea") {
        InputComponent = "textarea";
    }
    return (
        <div className="FormInput" style={style}>
            <ReactTooltip place="right" />
            <label htmlFor={props.name}>{props.label}:</label>
            <div className="InputWrap">
                <InputComponent
                    required={required}
                    type={props.type}
                    name={props.name}
                    {...fieldProps}
                    disabled={validating || disabled}
                    autoComplete={props.autocomplete}
                    tabIndex={props.tabindex}
                    {...overrideFieldProps}
                    ref={innerRef}
                />
            </div>
            <div style={{position: "relative"}} data-tip={(invalid && errors && errors.join(" ")) || props.hint || null}>
                <ValidationStatus valid={valid} invalid={invalid}>
                    {valid && <Icon animated name="check" height={16} width={16} stroke={"green"} />}
                    {invalid && <Icon animated name="x" height={16} width={16} stroke={"red"} />}
                </ValidationStatus>
            </div>
            {props.hint && !valid && !invalid && (
                <button data-tip={props.hint}>
                    <img src="/img/question-icon.svg" alt="" />
                </button>
            )}
        </div>
    );
};

export default createField(fieldPresets.input)(FormInput);
