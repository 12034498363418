const BreadcrumbItemNames = {
    CLASS_NAME: "className",
    CUSTOMER_NAME: "customerName",
    ORDER_NAME: "order_name",

    ORDER_ID: "order_id",
    ORDER_CLASS_ID: "order_class_id",
    EXPORT_ID: "export_id",
    PRINT_ID: "print_id",
    TICKET_ID: "ticket_id",

    TICKET_NAME: "ticketName",
    SHOP_ORDER_ID: "shop_order_id",
};

export default BreadcrumbItemNames;
