export const SET_PHOTOLAB = "SET_PHOTOLAB";
export const ADD_PHOTOLAB_ITEM = "ADD_PHOTOLAB_ITEM";
export const DELETE_PHOTOLAB_ITEM = "DELETE_PHOTOLAB_ITEM";
export const INCREMENT_PHOTOLAB_ITEM = "INCREMENT_PHOTOLAB_ITEM";
export const DECREMENT_PHOTOLAB_ITEM = "DECREMENT_PHOTOLAB_ITEM";

export const ADD_SHOP_ITEM = "ADD_SHOP_ITEM";
export const REMOVE_SHOP_ITEM = "REMOVE_SHOP_ITEM";
export const SET_PHOTOS = "SET_PHOTOS";
export const SET_SESSION_ORDER_CLASS = "SET_SESSION_ORDER_CLASS";
export const SET_SESSION_ORDER = "SET_SESSION_ORDER";
export const SET_ORDER_SESSION = "SET_SHOP_SESSION";
export const SET_SHOP = "SET_SHOP";
export const SET_ORDER_CLASS_RES = "SET_ORDER_CLASS_RES";
export const RESET_SHOP = "RESET_SHOP";
export const SET_DIGITALS = "TRIGGER_DIGITALS";
export const SET_METHOD = "SET_METHOD";
export const SET_VOUCHER = "SET_VOUCHER";
export const SET_PRICES = "SET_PRICES";
export const SET_FORMATS = "SET_FORMATS";
export const SET_IP = "SET_IP";
export const SET_PHOTOLAB_FORMATS = "SET_PHOTOLAB_FORMATS";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
export const SET_DELIVERY_METHOD = "SET_DELIVERY_METHOD";
export const SET_DELIVERY_BRANCH = "SET_DELIVERY_BRANCH";
export const SET_CARRIER = "SET_CARRIER";
export const SET_RELATIONSHIPS = "SET_RELATIONSHIPS";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_TRANSPORT_SERVICE = "SET_TRANSPORT_SERVICE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_ERROR = "SET_ERROR";
export const SET_EXPORT = "SET_EXPORT";
export const SET_ORDER_ADMIN_PAGE = "SET_ORDER_ADMIN_PAGE";
export const SET_EXPORTS = "SET_EXPORTS";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_ADMIN_NOTIFICATION = "SET_ADMIN_NOTIFICATION";
export const SET_PAYMENT = "SET_PAYMENT";
export const MUTE_NOTIFICATIONS = "MUTE_NOTIFICATIONS";
export const SET_ORDER = "SET_ORDER";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_ORDERS = "SET_ORDERS";
export const SET_USERS = "SET_USERS";
export const SET_ROLES = "SET_ROLES";
export const SET_PRINTS = "SET_PRINTS";
export const SET_TICKETS = "SET_TICKETS";
export const SET_POSSIBLE_WORKERS = "SET_POSSIBLE_WORKERS";
export const SET_PHOTO_FOLDERS = "SET_PHOTO_FOLDERS";
export const SET_ORDER_TYPES = "SET_ORDER_TYPES";
export const SET_ORDER_CLASSES = "SET_ORDER_CLASSES";
export const SET_SHOP_ORDERS = "SET_SHOP_ORDERS";
export const SET_SHOP_ORDER = "SET_SHOP_ORDER";
export const SET_SHOP_ORDER_DETAIL = "SET_SHOP_ORDER_DETAIL";
export const AJAX_LOADING = "AJAX_LOADING";
export const SET_SESSION = "SET_SESSION";

export const SET_ADDRESS = "SET_ADDRESS";
export const SET_DEBUG = "SET_DEBUG";
export const OPEN_CHAT = "OPEN_CHAT";
export const OPEN_MODAL = "OPEN_MODAL";
