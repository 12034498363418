import {gql, loader} from "graphql.macro";

const Queries = {
    Export: id => {
        const query = loader("graphql/Export.graphql");
        return {
            query,
            variables: {
                id: id,
            },
            fetchPolicy: "network-only",
        };
    },
    Orders: () => {
        const query = loader("graphql/Orders.graphql");
        return {
            query,
            variables: {},
        };
    },
    Order: id => {
        const query = loader("graphql/Order.graphql");
        return {
            query,
            variables: {
                order_id: id,
            },
            fetchPolicy: "network-only",
        };
    },
    Tickets: filters => {
        const query = loader("graphql/Tickets.graphql");
        return {
            query,
            variables: {
                ...filters,
                hasTags:
                    filters.tags.length === 0
                        ? null
                        : {
                              column: "TICKET_TAG_ID",
                              operator: "IN",
                              value: filters.tags,
                          },
            },
            fetchPolicy: "network-only",
        };
    },

    OrderClass: (order_id, order_class_id) => {
        const query = loader("graphql/OrderClass.graphql");
        return {
            query,
            variables: {
                order_id,
                order_class_id,
            },
            // fetchPolicy: "network-only"
        };
    },
    OrderDetailSidebar: order_id => {
        const query = loader("graphql/OrderDetailSidebar.graphql");
        return {
            query,
            variables: {
                order_id,
            },
        };
    },

    ShopOrder: shop_order_id => {
        const query = loader("graphql/ShopOrder.graphql");
        return {
            query,
            variables: {
                shop_order_id,
            },
            fetchPolicy: "network-only",
        };
    },
    ShopOrders: () => {
        const query = loader("graphql/ShopOrders.graphql");
        return {
            query,
            fetchPolicy: "network-only",
        };
    },
    PossibleWorkers: () => {
        const query = gql`
            {
                possibleWorkers {
                    id
                    name
                    roles
                }
            }
        `;
        return {
            query,
            fetchPolicy: "network-only",
        };
    },
};

export default Queries;
