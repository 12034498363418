import FormInput from "components/FormInput";
import "components/Forms/ClassLoginForm.scss";
//import Button from "components/Button";
import {Form} from "react-advanced-form";
import Button from "components/Button";
import React from "react";
import {loginToClass, openChat} from "helpers/actions";
import FormRules from "helpers/FormRules";
import {withRouter} from "react-router";
import store from "helpers/store";
import Ticker from "components/Ticker";
import {connect} from "react-redux";

//import {Button} from "react-advanced-form-addons";

class ClassLoginForm extends React.Component {
    state = {
        submitReady: false,
    };
    rules = FormRules.defaultRules();
    messages = {
        ...FormRules.defaultMessages(),
    };
    submit = e => {
        store.dispatch(loginToClass(e.serialized.password, this.props.history));
        return Promise.resolve();
    };
    setSubmitReady = yes => {
        this.setState({
            submitReady: yes,
        });
    };

    render() {
        const submitReady = this.state.submitReady;
        const setValid = (name, valid, invalid) => {
            if (valid && !submitReady) {
                this.setSubmitReady(true);
            } else if (invalid && submitReady) {
                this.setSubmitReady(false);
            }

            if (valid) {
                // @todo submit automatically
            }
        };
        return (
            <div className="LoginBox with-spark">
                <span className="title">
                    Objednat <b>fotografie</b>
                </span>
                <Form action={this.submit} method="POST" rules={this.rules} messages={this.messages}>
                    <div className="PersonalDetails">
                        <FormInput type="password" name="password" label="Heslo" required onValidChanged={setValid} />
                        <div className="buttons">
                            {/*<Button primary className={["SubmitOrderForm", submitReady ? "Active" : ""].join(" ")}>Zaplatit a <b>závazně objednat</b><TickIconSvg/></Button>*/}

                            <Button type="info" onClick={() => this.props.dispatch(openChat("open"))} htmlType={"button"}>
                                Potřebuji pomoc
                            </Button>
                            <Button type={this.state.submitReady && "login"} htmlType={"submit"}>
                                Otevřít galerii
                                <Ticker ticked={this.state.submitReady} />
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default connect()(withRouter(ClassLoginForm));
