import forEach from "helpers/forEach";
import PhotoUtils from "helpers/PhotoUtils";
import PhotoLab from "helpers/PhotoLab";

class Cart {
    items = [];
    method = null;
    formats = [];
    constructor(items, method) {
        this.items = items;
    }

    setFormats = formats => {
        this.formats = formats;
    };
    totalCount = photos => {
        return photos.reduce((last, cur) => last + cur.totalCount, 0);
    };

    getSummary = (photos, digitals, deliveryMethod, paymentMethod) => {
        //console.log(photos);
        const formatsArr = [];

        forEach(photos, function (photo, index) {
            forEach(photo.formats, function (size, indexA) {
                formatsArr.push(size);
            });
        });
        //const prices = store.getState().prices;
        const summary = [];
        formatsArr.reduce(function (res, value) {
            if (!res[value.name]) {
                res[value.name] = {name: value.name, count: 0, price: 0};
            }
            res[value.name].count += value.count;
            // only show those with actual count
            if (res[value.name].count > 0) {
                if (summary.filter(formatData => formatData.name === value.name).length === 0) {
                    summary.push(res[value.name]);
                }
            }

            //result.push(res[value.name]);
            res[value.name].price += value.price_per_one * value.count;
            return res;
        }, {});

        if (digitals) {
            summary["digitals"] = {
                name: "Digitály",
                count: 1,
                price: this.formats["digitals"]?.price || 0,
            };
        }

        if (deliveryMethod && paymentMethod) {
            summary["delivery"] = {
                name: deliveryMethod.name + " & " + paymentMethod.name,
                count: null,
                price: deliveryMethod.price,
            };
        }

        return summary;
    };

    digitalPrice = () => {
        return this.formats.find(format => format.id === 999)?.price || 0;
    };

    calculatePhotosPrice = (photos, digitals) => {
        const totalDigitalsPrice = digitals ? this.digitalPrice() : 0;
        if (!photos) {
            return totalDigitalsPrice;
        }
        const totalPhotosPrice = photos.reduce((prevTotal, photo) => {
            const formatTotal = photo.formats.reduce((prev, format) => {
                return prev + format.price_per_one * format.count;
            }, 0);
            return prevTotal + formatTotal;
        }, 0);

        return totalPhotosPrice + totalDigitalsPrice;
    };

    calculateTotalPrice = (photos, digitals, method, voucher, photoLabData) => {
        const photolabPrice = photoLabData ? PhotoLab.calculateTotalPrice(photoLabData) : 0;
        const totalPhotosPrice = this.calculatePhotosPrice(photos, false);
        const digitalsPrice = this.calculatePhotosPrice([], digitals);
        const totalMethodPrice = this.calculateMethodPrice(method);
        const voucherPrice = voucher ? voucher.value : 0;
        const balancePrice = totalPhotosPrice - voucherPrice;

        let totalPrice = balancePrice < 0 ? 0 : balancePrice;

        totalPrice += digitalsPrice + totalMethodPrice + photolabPrice;

        return totalPrice < 0 ? 0 : totalPrice;
    };

    calculateDeliveryPrice = method => {
        const deliveryPrice = method?.deliveryMethod?.price || 0;
        const paymentPrice = method?.paymentMethod?.price || 0;
        return deliveryPrice + paymentPrice;
    };
    calculateMethodPrice = method => {
        return this.calculateDeliveryPrice(method);
    };

    createModalImagesArray = (photos, formats) =>
        forEach(photos, photo => {
            return {
                source: {
                    thumbnail: PhotoUtils.path(photo, ["thumbnail", "medium"]),
                    regular: PhotoUtils.path(photo, ["medium", "thumbnail"]),
                },
                photo: photo,
            };
        });

    calculateTotalPriceFromState = state => this.calculateTotalPrice(state.cart.photos, state.digitals, state.method, state.voucher, state.photolab);

    getCartPhotos(cart, photolab) {
        return [...cart.photos, ...photolab.photos.map(photoL => photoL.photo).filter(r => r)];
    }
}

export default new Cart();
