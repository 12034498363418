import store from "helpers/store";
import {setRelationships} from "helpers/actions";

class Relationships {
    static resolve(itemOrStr) {
        if (typeof itemOrStr !== "string") {
            return itemOrStr;
        }

        const [_, relationName, id] = itemOrStr.split(".");
        const relation = store.getState().relationships[relationName] || null;
        if (!relation) {
            return null;
        }

        const item = relation.find(rel => {
            return +rel.id === +id;
        });
        return item;
    }

    static trySetRelationships(r) {}

    static trySetRelationshipsFromResponse(r) {
        if (!r.relationships) {
            return;
        }
        Object.keys(r.relationships).map(relKey => {
            if (relKey === "photos") {
                store.dispatch(
                    setRelationships({
                        photos: r.relationships.photos,
                    })
                );
            } else if (relKey === "formats") {
                store.dispatch(
                    setRelationships({
                        formats: r.relationships.formats,
                    })
                );
            }
        });
    }
}
export default Relationships;
