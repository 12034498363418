import React from "react";
import "./Svg.scss";

export function TickAnimationSvg() {
    return (
        <>
            <svg version="1.1" id="tick" x="0px" y="0px" viewBox="0 0 37 37">
                <path
                    className="circ path"
                    d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                />
                <polyline className="tick path" points="11.6,20 15.9,24.2 26.4,13.8 " />
            </svg>
        </>
    );
}

export function TickIconSvg() {
    return (
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16">
            <path
                id="path-1_1_"
                className="st0"
                d="M6.2,13.4c-0.2,0-0.5-0.1-0.6-0.3L1.4,8.5c-0.3-0.4-0.3-0.9,0-1.3c0.3-0.3,0.9-0.3,1.2,0l3.5,3.9l7.2-8.2c0.3-0.4,0.9-0.4,1.2-0.1c0.3,0.3,0.4,0.9,0.1,1.3l-7.8,8.9C6.6,13.3,6.4,13.4,6.2,13.4L6.2,13.4z"
            />
        </svg>
    );
}
