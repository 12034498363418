import React from "react";

import {useSelector} from "react-redux";
//import Fade from "@material-ui/core/Fade";
import SuspenseLoader from "components/SuspenseLoader";

const LinearProgress = React.lazy(() => import("@material-ui/core/LinearProgress"));
const Loader = props => {
    const ajaxLoading = useSelector(state => state.ajaxLoading);
    const none = <div style={{}}></div>;
    //console.log(ajaxLoading);

    if (ajaxLoading === 0 && props.usereducer) {
        return none;
    }
    //<LinearProgress />

    if (props.naked) {
        return <SuspenseLoader naked />;
    }
    return (
        <>
            {/*<Fade
                in={true}
                style={{
                    transitionDelay: props.usereducer ? "0ms" : "0ms",
                    width: "calc(100% - 72px)",
                    position: "fixed",
                    height: "4px",
                    right: "0",
                    zIndex: "100",
                }}
                unmountOnExit
            >
                {props.usereducer ? <LinearProgress /> : <SuspenseLoader />}
            </Fade>*/}
            <div
                style={{
                    width: "calc(100% - 72px)",
                    position: "absolute",
                    right: "0",
                    zIndex: "110",
                }}
            >
                {props.usereducer ? <LinearProgress /> : <SuspenseLoader />}
            </div>
        </>
    );
};

export default Loader;
