import React from "react";
import "./Svg.scss";
import {TickAnimationSvg} from "components/Svg";

const Ticker = props => {
    const ticked = typeof props.ticked === "undefined" || props.ticked === true;
    if (props.naked) {
        return (
            <span className={["WrapTick", ticked && "Ticked", props.color || ""].join(" ")}>
                <div className={["trigger", ticked && "drawn"].join(" ")}></div>
                <TickAnimationSvg />
            </span>
        );
    }
    return (
        <div className={["WrapTick", ticked && "Ticked", props.color || ""].join(" ")}>
            <div className={["trigger", ticked && "drawn"].join(" ")}></div>
            <TickAnimationSvg />
        </div>
    );
};
export default Ticker;
