export const GOOGLE_MAPS_PUBLIC_KEY = "AIzaSyAfS1EvTnPRzShMAZjsreykidNDGxBijws";
export const GOOGLE_ANALYTICS_KEY = "UA-60205697-4";
export const PROTOCOL = "https";
export const ROOT_STORAGE_DOMAIN = "api.skolyfoto.cz";
export const ROOT_STORAGE_WEB = `${PROTOCOL}://${ROOT_STORAGE_DOMAIN}/`;
export const ROOT_API_WEB = `${PROTOCOL}://api.skolyfoto.cz`;
export const ROOT_API = ROOT_API_WEB + "/api/";
export const CURRENCY = "Kč";
export const IS_DEV = document.location.href.indexOf("skolyfoto") === -1;
export const IS_PROD = !IS_DEV;
export const STRIPE_KEY_DEV = "pk_test_51InRIOFoABcdixI8FerU1pJyYCHBDHHQltVndlNOEr7ozyULNj6KeSTXK7R5vdlBFN4DNhXM4BV1G47DoaGJF7u800fJ4AVWie";
export const STRIPE_KEY_LIVE = "pk_live_51InRIOFoABcdixI8Mcg628fZdQdEpUhrgNQgozsaCIQPuY0KtRvgzFlAORv2ehLMqhaXnkHhonuKRcLVa8p6p4w900ji2MsaU4";
