import {ROOT_STORAGE_WEB} from "helpers/config";
import React from "react";
import Relationships from "helpers/Relationships";

class PhotoUtils {
    static findCompression = (photo, type) => {
        photo = Relationships.resolve(photo);

        if (type === "original") {
            return photo.path;
        }
        if (!photo.photoCompressions) {
            return photo.path;
        }
        const compression = photo.photoCompressions.find(photoCompression => photoCompression.type === type);
        return compression ? compression.path || null : null;
    };

    static path = (photo, types) => {
        if (!types || !types.length) {
            return PhotoUtils.path(photo, "thumbnail");
        }

        if (!Array.isArray(types)) {
            types = [types];
        }

        const compressionPaths = types
            .map(type => {
                const compression = PhotoUtils.findCompression(photo, type);

                return compression;
            })
            .filter(item => item !== null);

        if (compressionPaths.length === 0) {
            return null;
        }

        return compressionPaths[0].indexOf("http") === 0 ? compressionPaths[0] : ROOT_STORAGE_WEB + compressionPaths[0];
    };

    static blurred(photo) {
        return PhotoUtils.path(photo, "blurred");
    }
}

export default PhotoUtils;
