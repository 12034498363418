import {combineReducers} from "redux";
import * as Actions from "./actionTypes";
import {getLatLng} from "react-places-autocomplete";
import {IS_DEV} from "helpers/config";
import update from "react-addons-update";
import Cart from "helpers/Cart";

const initialState = {
    photos: [],
    totalPrice: 0,
    voucher: null,
    method: null,
};

function method(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_METHOD:
            return action.method;
        default:
            return state;
    }
}

function voucher(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_VOUCHER:
            return action.voucher;
        default:
            return state;
    }
}

function sessionOrderClassId(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_SESSION_ORDER_CLASS:
            return action.order_class_id;
        default:
            return state;
    }
}

function sessionOrderId(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_SESSION_ORDER:
            return action.order_id;
        default:
            return state;
    }
}

function language(state = "en", action) {
    if (typeof state === "undefined") {
        return "en";
    }

    switch (action.type) {
        case Actions.SET_LANGUAGE:
            return action.language;
        default:
            return state;
    }
}

function relationships(state = {}, action) {
    if (typeof state === "undefined") {
        return {};
    }

    const newState = {...state};
    switch (action.type) {
        case Actions.SET_RELATIONSHIPS:
            Object.keys(action.relationships).map(relationName => {
                newState[relationName] = action.relationships[relationName]; // replace or create
            });
            return newState;
        default:
            return state;
    }
}

function transportService(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_TRANSPORT_SERVICE:
            return action.transportService;
        default:
            return state;
    }
}

function deliveryMethod(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_DELIVERY_METHOD:
            return action.deliveryMethod;
        default:
            return state;
    }
}

function deliveryBranch(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_DELIVERY_BRANCH:
            return action.deliveryBranch;
        default:
            return state;
    }
}

function carrier(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_CARRIER:
            return action.carrier;
        default:
            return state;
    }
}

function paymentMethod(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_PAYMENT_METHOD:
            return action.paymentMethod;
        default:
            return state;
    }
}

function message(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_MESSAGE:
            //if (action.message) toast(action.message, {type: action.messageType});

            return action;
        default:
            return state;
    }
}

function adminNotification(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_ADMIN_NOTIFICATION:
            return action;
        default:
            return state;
    }
}

function notificationsMuted(state = false, action) {
    if (typeof state === "undefined") {
        return false;
    }

    switch (action.type) {
        case Actions.MUTE_NOTIFICATIONS:
            return action.value;
        default:
            return state;
    }
}

function session(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_SESSION:
            return action.session;
        default:
            return state;
    }
}

function orderClassRes(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_ORDER_CLASS_RES:
            return action.orderClassRes;
        case Actions.SET_SHOP_ORDER:
            const shopOrderIndex = state?.shopOrders ? state.shopOrders.findIndex(shopOrder => +shopOrder.id == +action.shopOrder.id) : -1;
            if (shopOrderIndex === -1) {
                return state;
            }
            const updated = update(state, {
                shopOrders: {
                    [shopOrderIndex]: {
                        $set: action.shopOrder,
                    },
                },
            });

            return updated;

        default:
            return state;
    }
}
function order(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_ORDER:
            return action.order;
        default:
            return state;
    }
}

function shopOrder(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_SHOP_ORDER:
            return action.shopOrder;
        default:
            return state;
    }
}
function shopOrderDetail(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_SHOP_ORDER_DETAIL:
            return action.shopOrder;
        default:
            return state;
    }
}
function orderAdminPage(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_ORDER_ADMIN_PAGE:
            return action.order;
        default:
            return state;
    }
}
function orders(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_ORDERS:
            return action.orders;
        default:
            return state;
    }
}

function users(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_USERS:
            return action.users;
        default:
            return state;
    }
}

function roles(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_ROLES:
            return action.roles;
        default:
            return state;
    }
}
function prints(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_PRINTS:
            return action.prints;
        default:
            return state;
    }
}

function exports(state = null, action) {
    if (typeof state === "undefined") {
        return [];
    }

    switch (action.type) {
        case Actions.SET_EXPORTS:
            return action.exports;
        default:
            return state;
    }
}
function exportDetail(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_EXPORT:
            return action.export;
        default:
            return state;
    }
}

function tickets(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_TICKETS:
            return action.tickets;
        default:
            return state;
    }
}
function orderClasses(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_ORDER_CLASSES:
            return action.orderClasses;
        default:
            return state;
    }
}
function shopOrders(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_SHOP_ORDERS:
            return action.shopOrders;
        default:
            return state;
    }
}
function possibleWorkers(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_POSSIBLE_WORKERS:
            return action.possibleWorkers;
        default:
            return state;
    }
}
function orderTypes(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_ORDER_TYPES:
            return action.orderTypes;
        default:
            return state;
    }
}
function photoFolders(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_PHOTO_FOLDERS:
            return action.photoFolders;
        default:
            return state;
    }
}

function address(state = null, action) {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case Actions.SET_ADDRESS:
            const resultAddress = action.address;
            const latLng = getLatLng(resultAddress);
            return {
                address_components: resultAddress.address_components,
                location: latLng,
                formatted_address: resultAddress.formatted_address,
                place_id: resultAddress.place_id,
                plus_code: resultAddress.plus_code,
                types: resultAddress.types,
            };
        default:
            return state;
    }
}

function ajaxLoading(state = 0, action) {
    if (typeof state === "undefined") {
        return 0;
    }

    switch (action.type) {
        case Actions.AJAX_LOADING:
            console.log("Ajax Loading +" + action.loading + " on URL " + action.url);
            return +state + action.loading;
        default:
            return state;
    }
}

function ajaxError(state = "", action) {
    if (typeof state === "undefined") {
        return "";
    }
    switch (action.type) {
        case Actions.SET_ERROR:
            return action.error;
        default:
            return state;
    }
}

function breadcrumbs(state = {}, action) {
    if (typeof state === "undefined") {
        return {};
    }

    switch (action.type) {
        case Actions.SET_BREADCRUMB:
            const newState = {...state};
            newState[action.name] = action.value;
            return newState;
        case Actions.SET_BREADCRUMBS:
            const newState2 = {...state, ...action.values};
            return newState2;
        default:
            return state;
    }
}

function digitals(state = false, action) {
    if (typeof state === "undefined") {
        return true;
    }

    switch (action.type) {
        case Actions.SET_DIGITALS:
            return action.value;
        default:
            return state;
    }
}

const cartInitialState = {
    photos: [],
    method: null,
};

function cart(state = cartInitialState, action) {
    if (typeof state === "undefined") {
        return cartInitialState;
    }

    if (typeof state.photos === "undefined") {
        return cartInitialState;
    }

    const formatsToZero = photoId => {
        return {
            ...state,
            photos: state.photos.map((item, index) => {
                if (item.id === action.itemId) {
                    item.formats = item.formats.map(format => {
                        format.count = 0;
                        format.price = 0;
                        return format;
                    });
                    item.totalCount = 0;
                }
                return item;
            }),
        };
    };

    const incrementFormat = (photoId, formatData, increment) => {
        return {
            ...state,
            photos: state.photos.map((item, index) => {
                if (item.id === action.itemId) {
                    const sizeItem = {...action.formatData};
                    if (increment < 0 && sizeItem.count + increment < 0) {
                        increment = 0;
                    }
                    sizeItem.count += increment;
                    sizeItem.price += sizeItem.price_per_one * increment;
                    item.formats = item.formats.map(format => {
                        if (format.id === sizeItem.id) {
                            return sizeItem;
                        }
                        return format;
                    });
                    item.totalCount += increment;
                }
                return item;
            }),
        };
    };

    switch (action.type) {
        case Actions.SET_PHOTOS:
            if (state.photos.length !== action.photos.length || state.photos[0]?.id !== action.photos[0]?.id) {
                return {
                    ...state,
                    photos: action.photos,
                };
            } else {
                return state;
            }

        case Actions.ADD_SHOP_ITEM:
            return incrementFormat(action.itemId, action.formatData, action.count || 1);
        //action.itemId;
        case Actions.REMOVE_SHOP_ITEM:
            if (action.formatData === "all") {
                return formatsToZero(action.itemId);
            }
            return incrementFormat(action.itemId, action.formatData, action.count || -1);
        default:
            return state;
    }
}

function photolab(state = undefined, action) {
    if (typeof state === "undefined") {
        return {
            photos: [],
        };
    }
    if (action.type === Actions.SET_PHOTOLAB) {
        return action.photolab;
    }
    if (!action.photo && !action.file && !action.photolab && !action.obj) {
        return state;
    }

    const photo = action.photo;
    let index = state.photos.findIndex(photo => action.photo?.id === photo.id || action.photo?.id === photo.photo?.id);
    const incrementFormat = increment => {
        const format = action.format;
        const formatIndex = state.photos[index].formats.findIndex(f => f.id === format.id);
        const newState = update(state, {
            photos: {
                [index]: {
                    photo: {
                        formats: {
                            [formatIndex]: {
                                count: {$apply: x => +x + +increment},
                                price: {
                                    $apply: x => {
                                        return +(x || 0) + +increment * +format.price;
                                    },
                                },
                            },
                        },
                    },
                    formats: {
                        [formatIndex]: {
                            count: {$apply: x => +x + +increment},
                            price: {
                                $apply: x => {
                                    return +(x || 0) + +increment * +format.price;
                                },
                            },
                        },
                    },
                },
            },
        });
        const formats = newState.photos[index].formats;
        newState.photos[index].photo.formats = [...formats];
        return {
            ...newState,
        };
    };

    if (action.type === Actions.SET_PHOTOLAB) {
        return action.photolab;
    } else if (action.type === Actions.ADD_PHOTOLAB_ITEM) {
    } else if (action.type === Actions.DELETE_PHOTOLAB_ITEM) {
        let actualIndex = index;
        if (action.obj) {
            actualIndex = state.photos.findIndex(photo => action.obj.id === photo.id);
        } else if (action.file) {
            actualIndex = state.photos.findIndex(photo => action.file.meta.id === photo.file.meta.id);
        }
        return update(state, {
            photos: {
                $splice: [[actualIndex, 1]],
            },
        });
    } else if (action.type === Actions.INCREMENT_PHOTOLAB_ITEM) {
        return incrementFormat(action.increment || 1);
    } else if (action.type === Actions.DECREMENT_PHOTOLAB_ITEM) {
        return incrementFormat(-action.increment || -1);
    } else {
        return state;
    }
}

function debug(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    if (action.type === Actions.SET_DEBUG && IS_DEV) {
        return action.data;
    } else {
        return state;
    }
}

function chat(state = false, action) {
    if (typeof state === "undefined") {
        return false;
    }

    if (action.type === Actions.OPEN_CHAT) {
        if (action.value !== undefined) {
            return action.value;
        }
        return "open";
    } else {
        return state;
    }
}

function modal(state = null, action) {
    if (typeof state === "undefined") {
        return {
            component: null,
        };
    }
    if (action.type === Actions.OPEN_MODAL) {
        if (action.toggle) {
            if (state && state.component && state.component === action.component) {
                return {
                    component: null,
                    props: {
                        ...state.props,
                        ...(action.props || {}),
                    },
                };
            }
        }
        return action.component
            ? {
                  ...action,
              }
            : null;
    } else {
        return state;
    }
}
function payment(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_PAYMENT:
            return action.payment;
        default:
            return state;
    }
}

function photos(state = [], action) {
    if (typeof state === "undefined") {
        return initialState;
    }

    switch (action.type) {
        case Actions.SET_PHOTOS:
            return action.photos;
        default:
            return state;
    }
}

function shop(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    switch (action.type) {
        case Actions.SET_SHOP:
            return action.shop;
        default:
            return state;
    }
}

function darkMode(state = null, action) {
    if (typeof state === "undefined") {
        return null;
    }

    if (action.type === Actions.SET_DARK_MODE) {
        return action.mode;
    } else {
        return state;
    }
}

function formats(state = [], action) {
    if (typeof state === "undefined") {
        return [];
    }

    if (action.type === Actions.SET_FORMATS) {
        Cart.setFormats(action.formats);

        return action.formats;
    } else {
        return state;
    }
}

/*
function totalPrice(state = 0, action) {
    if (typeof state === "undefined") {
        return initialState;
    }

    var newState = +state;
    switch(action.type) {


        case Actions.SET_METHOD:
            if (action.oldMethod) {
                newState -= action.oldMethod.price;
            }
            return action.method ? newState + +action.method.price : newState;
        case Actions.TRIGGER_DIGITALS:
            return action.value === true ? +state + +action.formatData.price : +state - action.formatData.price;
        case Actions.ADD_SHOP_ITEM:
            return state + +action.formatData.price;
        case Actions.REMOVE_SHOP_ITEM:
            return state - action.formatData.price;

        default: return state;
    }
}
*/
const reducers = combineReducers({
    message,
    adminNotification,
    darkMode,
    breadcrumbs,
    photos,
    digitals,
    //  totalPrice,
    voucher,
    method,
    paymentMethod,
    transportService,
    deliveryMethod,
    deliveryBranch,
    carrier,
    shop,
    formats,
    session,
    order,
    orderAdminPage,
    orderClassRes, // admin
    export: exportDetail,
    exports,
    prints,
    orders,
    users,
    roles,
    tickets,
    orderClasses,
    shopOrders,
    notificationsMuted,
    possibleWorkers,
    orderTypes,
    photoFolders,
    address,
    ajaxLoading,
    ajaxError,
    cart,
    photolab,
    payment,
    sessionOrderClassId,
    sessionOrderId,
    language,
    modal,
    debug,
    shopOrder,
    shopOrderDetail,
    chat,
    relationships,
});

export default reducers;
