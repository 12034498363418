import {ApolloClient, InMemoryCache} from "@apollo/client";
import {ROOT_API_WEB} from "helpers/config";
//import {setContext} from "@apollo/client/link/context";
//import {createHttpLink} from "@apollo/client/link/http";
const cache = new InMemoryCache({
    dataIdFromObject: object => (object.id ? object.id + object.__typename : null),
});

const graphql = new ApolloClient({
    uri: ROOT_API_WEB + "/graphql",
    cache,
    credentials: "include",
    withCredentials: true,
    connectToDevTools: true,
    queryDeduplication: false,
});
export default graphql;
