import axios from "axios";
import AxiosCommon from "helpers/axios/AxiosCommon";
import store from "helpers/store";
import {setError} from "helpers/actions";
import localforage from "localforage";
import {setupCache} from "axios-cache-adapter";
import {ROOT_API} from "helpers/config";

import React from "react";

export const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [
        //memoryDriver,
        //localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        //memoryDriver._driver
    ],
    // Prefix all storage keys to prevent conflicts
    name: "axiosCache",
});

const cache = setupCache({
    maxAge: window.location.href.indexOf("localhost") !== -1 ? -1 : -1, //1000 * 1 : 20 * 1000, // ms, disabled for develop
    store: forageStore,
});
// override per request options
/* cache: {
    maxAge: 2 * 60 * 1000, // 2 min instead of 15 min
    exclude: { query: false }
  }
 */

const instance = axios.create({
    baseURL: ROOT_API,
    adapter: cache.adapter,
    withCredentials: true,
});
instance.get(ROOT_API.replace("/api/", "") + "/sanctum/csrf-cookie");

instance.defaults.withCredentials = true;
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.defaults.headers.common["Content-Type"] = "application/json";
AxiosCommon.applyRequestInterceptor(instance, setError, store);
AxiosCommon.applyResponseInterceptor(instance, setError, store);

export default instance;
