import {IS_PROD} from "helpers/config";
import React, {useEffect} from "react";
import {Redirect, Route, Switch} from "react-router";
import "./FrontLayout.scss";
import {getQueryParams} from "helpers/helpers";
import Api from "helpers/Api";
import ShopNotReady from "pages/Front/Shop/ShopNotReady";
import Chat from "components/Chat";
import PageNotFound from "pages/Front/PageNotFound/PageNotFound";
import store from "helpers/store";
import ReactLazyPreload from "components/ReactLazyPreload";
import "react-toastify/dist/ReactToastify.css";

const Shop = ReactLazyPreload(() => import("pages/Front/Shop/Shop"));
const Order = ReactLazyPreload(() => import("pages/Front/Order/Order"));
const Index = ReactLazyPreload(() => import("pages/Front/Index/Index"));
const OrderLogin = ReactLazyPreload(() => import("pages/Front/Order/OrderLogin"));
const Payment = ReactLazyPreload(() => import("pages/Front/Payment/Payment"));
const ShopLogin = ReactLazyPreload(() => import("pages/Front/Shop/ShopLogin"));
const Complaint = ReactLazyPreload(() => import("pages/Front/Complaint/Complaint"));
const ToastContainer = ReactLazyPreload(() => import("components/ToastContainer"));
const CitiesLanding = ReactLazyPreload(() => import("pages/Front/CitiesLanding/CitiesLanding"));
const Reservation = ReactLazyPreload(() => import("pages/Front/Reservation/Reservation"));

const FrontLayout = () => {
    const loadSmartlook = () => {
        window.smartlook ||
            (function(d) {
                var o = (window.smartlook = function() {
                    o.api.push(arguments);
                });
                var h = d.getElementsByTagName("head")[0];
                var c = d.createElement("script");
                o.api = [];
                c.async = true;
                c.type = "text/javascript";
                c.charset = "utf-8";
                c.src = "https://rec.smartlook.com/recorder.js";
                c.defer = true;
                setTimeout(function() {
                    h.appendChild(c);
                }, 100);
            })(document);
    };
    useEffect(() => {
        if (IS_PROD) {
            loadSmartlook();
            setTimeout(function() {
                try {
                    window.smartlook("init", "47d279b78717e02518ee62d28cb8d2f6f865dd6d");
                } catch (e) {}

                Api.getIp().then(r => {
                    if (r && r.ip) {
                        if (window.smartsupp) {
                            window.smartsupp("variables", {
                                ip: r.ip,
                                ...r.data,
                            });
                        }
                        try {
                            window.smartlook("indentify", r.ip, r.data || {});
                        } catch (e) {}
                    }
                });
            }, 500);
            const queryParams = getQueryParams();

            if (queryParams) {
                if (queryParams.izo) {
                    Api.getLead(queryParams.izo)
                        .then(r => {
                            if (r.lead) {
                                window.smartsupp("variables", {
                                    ...queryParams,
                                    school: r.lead,
                                });
                            } else {
                                window.smartsupp("variables", {
                                    ...queryParams,
                                });
                            }
                        })
                        .catch(e => {
                            window.smartsupp("variables", {
                                ...queryParams,
                            });
                        });
                }
            }
        }

        return () => {
            //window.removeEventListener("load", loadSmartlook);
        };
    }, []);
    return (
        <>
            <Switch>
                <Route path="/complaint">
                    <Complaint />
                </Route>
                <Route path="/shop/login">
                    <ShopLogin />
                </Route>
                <Route path="/shop/not-ready">
                    <ShopNotReady />
                </Route>
                <Route path="/shop/:order_class_id">
                    <Shop />
                </Route>
                <Route path="/shop/">{store.getState()?.cart ? <Shop /> : <Redirect to="/shop/login" />}</Route>
                <Route path="/payment/:shop_order_id/:hash">
                    <Payment />
                </Route>
                <Route path="/order/login">
                    <OrderLogin />
                </Route>
                <Route path="/order/:order_id?">
                    <Order />
                </Route>
                <Route path="/city" exact>
                    <CitiesLanding />
                </Route>
                <Route path="/reservation" exact>
                    <Reservation />
                </Route>
                <Route path="/" exact>
                    <Index />
                </Route>
                <Route>
                    <PageNotFound />
                </Route>
            </Switch>
            <Chat />
            <ToastContainer autoClose={7000} newestOnTop={true} pauseOnFocusLoss={true} />
        </>
    );
};

export default FrontLayout;
