import React from "react";
import {withRouter} from "react-router";
import "pages/Front/PageNotFound/PageNotFound.scss";
import Icon from "components/Icon";

const PageNotFound = props => {
    return (
        <div className="PageNotFound">
            <div className="container">
                <header>
                    <a href="/" className="logo">
                        <img src="/img/skolyfrontlogo.svg" alt="" />
                    </a>
                </header>
                <div className="PageNotFound-content">
                    <h1>Tato stránka neexistuje :(</h1>
                    <a href="/" className="blue-button">
                        <Icon type="ArrowBackIcon" /> Zpět na hlavní stránku
                    </a>
                </div>

                <footer>
                    <span className="copyright">FLATE Group s.r.o. | ŠkolyFoto.cz</span>
                </footer>
            </div>
        </div>
    );
};

export default withRouter(PageNotFound);
