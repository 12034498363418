import {useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import store from "helpers/store";
import {openChat} from "helpers/actions";
import SuspenseLoader from "components/SuspenseLoader";
import Api from "helpers/Api";

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

const Chat = props => {
    const chat = useSelector(state => state.chat);
    const forceUpdate = useForceUpdate();

    const interval = useRef();
    useEffect(() => {
        //store.dispatch(openChat(false));
    }, []);
    const load = () => {
        if (window.SMARTSUPP_LOADED || window._smartsupp) {
            return;
        }
        doLoad();
        //window.addEventListener("load", doLoad);
    };
    const doLoad = () => {
        if (chat !== "load" && chat !== "open") {
            return;
        }
        window._smartsupp = window._smartsupp || {};
        window._smartsupp.key = "f73c409701f1486a5bd04c533b2c0b3934f4bd31";
        window.smartsupp ||
            (function (d) {
                var s, c;
                window.o = window.smartsupp = function () {
                    window.o._.push(arguments);
                };
                window.o._ = [];
                s = d.getElementsByTagName("script")[0];
                c = d.createElement("script");
                c.type = "text/javascript";
                c.charset = "utf-8";
                c.async = true;
                c.src = "https://www.smartsuppchat.com/loader.js?";
                s.parentNode.insertBefore(c, s);
            })(document);

        Api.getIp().then(r => {
            if (r && r.ip) {
                if (window.smartsupp) {
                    window.smartsupp("variables", {
                        ip: r.ip,
                        ...r.data,
                    });
                }
                //window.smartlook("indentify", r.ip, r.data || {});
            }
        });
    };

    const waitForLoad = () => {
        if (interval.current) {
            clearInterval(interval.current);
        }
        interval.current = setInterval(() => {
            if (window.SMARTSUPP_LOADED) {
                document.querySelector("#chat-application-iframe")?.contentWindow?.document?.querySelector(".fab")?.click();
                document.querySelector("#chat-application-iframe")?.contentWindow?.document?.querySelector("#chatInput")?.focus();

                window.smartsupp("chat:open");
                store.dispatch(openChat("load"));
                forceUpdate();
                clearInterval(interval.current);
            }
        }, 100);
    };

    useEffect(() => {
        if (chat === "open" || chat === "load") {
            load();
        }
        if (chat === "open") {
            waitForLoad();
        }

        if (chat === false) {
            // window._smartsupp.hideWidget = true;
            //window._smartsupp.hideMobileWidget = true;

            document
                ?.querySelector("#chat-application")
                ?.querySelector("#chat-application-iframe")
                ?.contentWindow?.document?.getElementsByClassName("MuiIconButton-label")[1]
                ?.click();
        }

        return () => {
            //window.removeEventListener("load", doLoad);
        };
    }, [chat]);

    useEffect(() => {
        if (chat === "load") {
            load();
        }
    }, []);

    return (
        chat === "open" &&
        !window.SMARTSUPP_LOADED && (
            <>
                <SuspenseLoader />
            </>
        )
    );
};
export default Chat;
