import {applyMiddleware, compose, createStore} from "redux";
import reducers from "./reducers";
import {loadState, saveState} from "helpers/localStorage.js";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";

const persistedState = loadState();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer(action) {
        if (["SET_FORMATS", "SET_SHOP_ORDERS", "SET_ORDERS", "SET_BREADCRUMBS"].includes(action.type)) {
            return null;
        }
        return action;
    },
    stateTransformer: state => {
        // Transform the state to remove sensitive information
        const transformedState = {
            ...state,
            adminNotification: null,
            breadcrumbs: null,
            formats: null,
            order: null,
            orderClasses: null,
            shopOrders: null,
            orderAdminPage: null,
            prints: null,
            orders: null,
        };

        return transformedState;
    },
});
const store = createStore(reducers, persistedState, compose(applyMiddleware(thunk), sentryReduxEnhancer));

store.subscribe(() => {
    saveState(store.getState());
});

export default store;
