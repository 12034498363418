import Api from "helpers/Api";
import {toast} from "react-toastify";
import * as Actions from "./actionTypes";
import LinkTo from "helpers/LinkTo";
import graphql from "helpers/graphql";
import browserHistory from "helpers/history";
import Queries from "helpers/Queries";
import AxiosCommon from "helpers/axios/AxiosCommon";
import BreadcrumbItemNames from "entities/BreadcrumbItemNames";
import Cart from "helpers/Cart";
import update from "react-addons-update";
import forEach from "helpers/forEach";

export const ShopItemOperations = {
    INCREMENT: "INCREMENT",
    DECREMENT: "DECREMENT",
};

export function resetShop() {
    return dispatch => {
        dispatch(setPhotos([]));
        dispatch(setFormats([]));
        dispatch(setMethod(null));
        //dispatch(setSession(null));
        dispatch(setOrderId(null));

        dispatch(setOrderClassId(null));
        //dispatch(setShopOrderId(null));
        dispatch(setDigitals(false));
    };
}
export function submitComplaint(data) {
    return dispatch => {
        //dispatch(resetShop());
        return Api.submitComplaint(data).catch(e => console.log(e));
    };
}

export function loginToClass(password, history) {
    return dispatch => {
        //dispatch(resetShop());
        Api.loginToShop(password)
            .then(r => {
                console.log(r);
                dispatch(setOrderClassId(r.order_class_id));
                dispatch(setSession(r.session));
                if (r.is_master) {
                    history.push(LinkTo.order() + "/" + r.order_id + "/" + r.order_token);
                } else {
                    history.push(LinkTo.shop());
                }
            })
            .catch(e => console.error(e));
    };
}

export function loginToOrder(password, history) {
    return dispatch => {
        dispatch(resetShop());
        Api.loginToOrder(password)
            .then(r => r.order_id || null)
            .then(r => dispatch(setOrderId(r)))
            .then(r => {
                history.push(LinkTo.order());
            })
            .catch(e => console.error(e));
    };
}

export function loginAdmin(data, registerToken, history) {
    return (dispatch, getState) => {
        return Api.loginToAdmin({...data, register_token: registerToken}).then(r => {
            dispatch(setSession(r));
            return r;
        });
    };
}

export function logout() {
    return dispatch => {
        return Api.logout().then(r => {
            dispatch(setSession(null));
            return r;
        });
    };
}

export function setOrderClassId(order_class_id) {
    return {
        type: Actions.SET_SESSION_ORDER_CLASS,
        order_class_id,
    };
}

export function setOrderId(order_id) {
    return {
        type: Actions.SET_SESSION_ORDER,
        order_id,
    };
}

export function setPhotos(photos) {
    return {
        type: Actions.SET_PHOTOS,
        photos,
    };
}

export function setShop(shop) {
    return {
        type: Actions.SET_SHOP,
        shop,
    };
}

export function setTransportService(service) {
    return setMethodPiece("transportService", Actions.SET_TRANSPORT_SERVICE, service);
}

export function setPaymentMethod(method) {
    return setMethodPiece("paymentMethod", Actions.SET_PAYMENT_METHOD, method);
}

export function setDeliveryMethod(method) {
    return setMethodPiece("deliveryMethod", Actions.SET_DELIVERY_METHOD, method);
}

export function setDeliveryBranch(branch) {
    return setMethodPiece("deliveryBranch", Actions.SET_DELIVERY_BRANCH, branch);
}

export function setCarrier(carrier) {
    return setMethodPiece("carrier", Actions.SET_CARRIER, carrier);
}

export function setPhotolab(photolab) {
    return {
        type: Actions.SET_PHOTOLAB,
        photolab,
    };
}
export function photolabPhotoUploaded(file, photo) {
    return (dispatch, getState) => {
        const state = getState().photolab;
        const formats = getState().formats;
        const formatsObj = forEach(formats, format => {
            return {
                id: format.id,
                name: format.name,
                label: format.label,
                price_per_one: format.price,
                price: 0,
                count: 0,
                type: "photolab",
            };
        });
        const photoObj = {
            id: Math.random(),
            file: file,
            photo: photo
                ? {
                      ...photo,
                      formats: formatsObj,
                  }
                : null,
            formats: formatsObj,
        };
        const index = state.photos.findIndex(photoUp => photoUp.file.meta.id === file.meta.id);
        if (index !== -1) {
            const newState = update(state, {
                photos: {
                    [index]: {$set: photoObj},
                },
            });

            // update after upload
            dispatch(setPhotolab(newState));
            return null;
        }

        const newState = {
            ...state,
            photos: [photoObj, ...state.photos],
        };
        dispatch(setPhotolab(newState));
        return newState;
    };
}
export function photolabDeletePhoto(photo) {
    return {
        type: Actions.DELETE_PHOTOLAB_ITEM,
        photo,
    };
}
export function photolabDeleteFile(file) {
    return {
        type: Actions.DELETE_PHOTOLAB_ITEM,
        file,
    };
}

export function photolabDelete(obj) {
    return {
        type: Actions.DELETE_PHOTOLAB_ITEM,
        obj,
    };
}

export function photolabRemoveFromCart(photo) {
    return (dispatch, getState) => {
        const state = getState().photolab;
        let photolabPhoto = state.photos.find(plPhoto => plPhoto.id === photo.id);
        if (!photolabPhoto) {
            photolabPhoto = state.photos.find(plPhoto => plPhoto.photo?.id === photo.id);
        }
        photolabPhoto.formats
            .filter(format => format.count > 0)
            .map(format => {
                dispatch(photolabIncrementFormat(photo, format, -format.count));
            });
    };
}

export function photolabIncrementFormat(photo, format, increment = 1) {
    return {
        type: Actions.INCREMENT_PHOTOLAB_ITEM,
        photo,
        format,
        increment,
    };
}

export function photolabDecrementFormat(photo, format, decrement = 1) {
    return {
        type: Actions.DECREMENT_PHOTOLAB_ITEM,
        photo,
        format,
        increment: decrement, // intentional
    };
}

const setMethodPiece = (key, action, methodPiece) => {
    return (dispatch, getState) => {
        dispatch(
            setMethod({
                ...getState().method,
                [key]: methodPiece,
            })
        );

        return dispatch({
            type: action,
            [key]: methodPiece,
        });
    };
};

export function setPrices(prices) {
    return {
        type: Actions.SET_PRICES,
        prices,
    };
}

export function setFormats(formats) {
    const newFormats = forEach(formats, format => {
        return format;
    });
    return {
        type: Actions.SET_FORMATS,
        formats: newFormats,
    };
}
export function setIp(ip) {
    return {
        type: Actions.SET_FORMATS,
        ip,
    };
}

export function setAddress(address) {
    return {
        type: Actions.SET_ADDRESS,
        address,
    };
}

export function setDebug(data) {
    return {
        type: Actions.SET_DEBUG,
        data: data,
    };
}
export function openChat(value) {
    return {
        type: Actions.OPEN_CHAT,
        value: value,
    };
}
export function openModal(component, props) {
    return {
        type: Actions.OPEN_MODAL,
        component,
        props,
    };
}

export function openPhotosModal(id, disableGallery) {
    return (dispatch, getState) => {
        const state = getState();
        const modalImages = Cart.createModalImagesArray(state.cart.photos);
        const photoIndex = modalImages.findIndex(image => image.photo.id === id);
        return dispatch(toggleModal("PhotoModal", {photoId: id, photoIndex: photoIndex, disableGallery}));
    };
}

export function toggleModal(component, props) {
    return {
        type: Actions.OPEN_MODAL,
        toggle: true,
        component,
        props,
    };
}

export function setAjaxLoading(loading, url) {
    return {
        type: Actions.AJAX_LOADING,
        loading,
        url,
    };
}

export function setSession(session) {
    return {
        type: Actions.SET_SESSION,
        session,
    };
}

export function setError(message) {
    return {
        type: Actions.SET_MESSAGE,
        message,
        messageType: "error",
    };
}

export function addFlashMessage(message, messageType) {
    return {
        type: Actions.SET_MESSAGE,
        message,
        messageType,
    };
}

export function setSuccess(message) {
    return {
        type: Actions.SET_MESSAGE,
        message,
        messageType: "success",
    };
}
export function setDarkMode(mode) {
    return {
        type: Actions.SET_DARK_MODE,
        mode,
    };
}
export function setOrder(order) {
    return {
        type: Actions.SET_ORDER,
        order,
    };
}
export function setOrderAdminPage(order) {
    return {
        type: Actions.SET_ORDER_ADMIN_PAGE,
        order,
    };
}
export function fetchPossibleWorkers() {
    return dispatch => {
        return graphql.query(Queries.PossibleWorkers()).then(result => {
            dispatch(setPossibleWorkers(result.data.possibleWorkers));
            return result.data;
        });
    };
}

export function fetchPrints() {
    return dispatch => {
        return Api.getPrints().then(r => {
            dispatch(setPrints(r.prints));
        });
    };
}
export function reportRightClick(props) {
    return dispatch => {
        return Api.reportRightClick(props);
    };
}

export function fetchPrint(id) {
    return (dispatch, getState) => {
        if (getState().prints === null) {
            return Api.getPrints().then(r => {
                fetchPrint(id);
            });
        }

        return Api.getPrint(id).then(r => {
            const prints = getState().prints;
            const printsIndex = prints.findIndex(print => r.print.id === print.id);
            const updated = update(prints, {
                [printsIndex]: {
                    $set: r.print,
                },
            });
            dispatch(setPrints(updated));
        });
    };
}

export function setPrints(prints) {
    return {
        type: Actions.SET_PRINTS,
        prints,
    };
}
export function setUsers(users) {
    return {
        type: Actions.SET_USERS,
        users,
    };
}
export function setRoles(roles) {
    return {
        type: Actions.SET_ROLES,
        roles,
    };
}
export function exportPrintJob(print) {
    return dispatch => {
        return Api.exportPrintJob(print);
    };
}

export function confirmPrintJob(print) {
    return dispatch => {
        return Api.confirmPrintJob(print);
    };
}

export function cancelPrintJob(print) {
    //.then(this.props.alert.show("Fronta smazána"));
    return dispatch => {
        return Api.cancelPrintJob(print);
    };
}

export function confirmPrintJobReceived(print) {
    return dispatch => {
        return Api.confirmPrintJobReceived(print);
    };
}

export function setPhotoFolders(photoFolders) {
    return {
        type: Actions.SET_PHOTO_FOLDERS,
        photoFolders,
    };
}

export function setPossibleWorkers(possibleWorkers) {
    return {
        type: Actions.SET_POSSIBLE_WORKERS,
        possibleWorkers,
    };
}

export function setOrderTypes(orderTypes) {
    return {
        type: Actions.SET_ORDER_TYPES,
        orderTypes,
    };
}

export function setOrders(orders) {
    return {
        type: Actions.SET_ORDERS,
        orders,
    };
}

export function setTickets(tickets) {
    return {
        type: Actions.SET_TICKETS,
        tickets,
    };
}

export function setShopOrders(shopOrders) {
    return {
        type: Actions.SET_SHOP_ORDERS,
        shopOrders,
    };
}

export function setOrderClasses(orderClasses) {
    return {
        type: Actions.SET_ORDER_CLASSES,
        orderClasses,
    };
}

export function fetchOrders(ordersQuery) {
    return dispatch => {
        return Api.getOrders(ordersQuery).then(r => {
            dispatch(setOrders(r.orders.data));
            dispatch(setPossibleWorkers(r.possibleWorkers));
            dispatch(setOrderTypes(r.orderTypes));
            dispatch(setPhotoFolders(r.photoFolders));
        });
    };
}

export function setOrderClassRes(orderClassRes) {
    return {
        type: Actions.SET_ORDER_CLASS_RES,
        orderClassRes,
    };
}

export function fetchShopOrder(shop_order_id, intoDetail = false) {
    return (dispatch, getState) => {
        return graphql
            .query(Queries.ShopOrder(shop_order_id))
            .then(result => {
                return result.data;
            })
            .then(r => {
                const shopOrders = getState().shopOrders;
                if (!shopOrders) {
                    dispatch(setShopOrder(r.shopOrder));

                    if (getState().shopOrderDetail?.id === r.shopOrder.id || intoDetail) {
                        dispatch(setShopOrderDetail(r.shopOrder));
                    }
                }
                if (shopOrders && shopOrders.length) {
                    const index = shopOrders.findIndex(shopOrder => +shop_order_id === +shopOrder.id);
                    let updated = shopOrders;

                    if (index !== -1) {
                        let updated = update(shopOrders, {
                            [index]: {
                                $set: {
                                    ...r.shopOrder,
                                },
                            },
                        });
                        dispatch(setShopOrders(updated));
                    }
                }
                dispatch(setShopOrder(r.shopOrder));

                if (getState().shopOrderDetail?.id === r.shopOrder.id || intoDetail) {
                    dispatch(setShopOrderDetail(r.shopOrder));
                }
            });
    };
}

export function setShopOrder(shopOrder) {
    return {
        type: Actions.SET_SHOP_ORDER,
        shopOrder,
    };
}

export function setShopOrderDetail(shopOrder) {
    return {
        type: Actions.SET_SHOP_ORDER_DETAIL,
        shopOrder,
    };
}

export function fetchOrderClass(order_id, order_class_id) {
    return dispatch => {
        return graphql
            .query(Queries.OrderClass(order_id, order_class_id))
            .then(result => {
                return result.data;
            })
            .then(r => {
                r = AxiosCommon.toCamelCase(r);
                dispatch(
                    setOrderClassRes({
                        order: r.order,
                        class: r.class,
                        shopOrders: r.shopOrders,
                    })
                );
                /*alert("cool");
                console.log(r);
                r.shopOrders.map(g => {
                    g.shopOrderItemsSummary.map(h => {
                        console.log(h.photoFormats[3].count);
                    })
                })*/
                //store.dispatch(setBreadcrumb(BreadcrumbItemNames., r.class.name));
                //getexport
                dispatch(setBreadcrumb(BreadcrumbItemNames.ORDER_NAME, r.order.name));
                dispatch(setBreadcrumb(BreadcrumbItemNames.ORDER_CLASS_ID, r.class.id));
                dispatch(setBreadcrumb(BreadcrumbItemNames.CLASS_NAME, r.class.name));
                dispatch(setBreadcrumb(BreadcrumbItemNames.ORDER_ID, r.order.id));
            });
    };
}

export function fetchShopOrders() {
    return dispatch => {
        return graphql
            .query(Queries.ShopOrders())
            .then(result => {
                return result.data;
            })
            .then(r => {
                r = AxiosCommon.toCamelCase(r);
                dispatch(setShopOrders(r.shopOrders));
            });
    };
}

export function deleteExport(exportDetail) {
    return dispatch => {
        return Api.deleteExport(exportDetail);
    };
}

export function confirmExportReceived(exportDetail) {
    return dispatch => {
        return Api.confirmExportReceived(exportDetail).then(r => {
            dispatch(fetchExport(exportDetail.id));
            return r;
        });
    };
}

export function confirmExportShipped(exportDetail) {
    return dispatch => {
        return Api.confirmExportShipped(exportDetail).then(r => {
            dispatch(fetchExport(exportDetail.id));
            return r;
        });
    };
}

export function fetchExports(type, filters) {
    return (dispatch, getState) => {
        return Api.getExports(type, filters).then(r => {
            dispatch(setExports(r.exports));
            return r;
        });
    };
}

export function moveToMaster(_export, transform) {
    return (dispatch, getState) => {
        return Api.moveExportToMaster(_export, transform).then(r => {
            if (r.ok) {
                dispatch(setExport(null, transform));
            }
            return r;
        });
    };
}

export function fetchExport(id) {
    return (dispatch, getState) => {
        return graphql
            .query(Queries.Export(id))
            .then(r => r.data)
            .then(r => {
                if (r.export === null) {
                    toast.error("Export not found.");
                    return;
                }
                dispatch(setBreadcrumb(BreadcrumbItemNames.EXPORT_ID, r.export.id + " (" + r.export.carrier?.name + ")"));
                const exports = getState().exports;
                let exportIndex;
                let correctDate;
                forEach(exports, (_exportsAtDate, date) => {
                    exportIndex = _exportsAtDate.findIndex(_export => +id === +_export.id);
                    if (exportIndex !== -1) {
                        correctDate = date;
                        return false;
                    }
                });
                let updated = exports;
                if (correctDate && exportIndex) {
                    updated = update(exports, {
                        [correctDate]: {
                            [exportIndex]: {
                                $set: r.export,
                            },
                        },
                    });
                }

                dispatch(setExports(updated));
                dispatch(setExport(r.export));
                return r;
            });
    };
}

export function setExports(exports) {
    return {
        type: Actions.SET_EXPORTS,
        exports,
    };
}

export function setExport(export_) {
    return {
        type: Actions.SET_EXPORT,
        export: export_,
    };
}

export function fetchPrices() {
    return dispatch => {
        return Api.getPrices().then(r => {
            dispatch(setFormats(r));
        });
    };
}
export function fetchIp() {
    return dispatch => {
        return Api.getIp().then(r => {
            dispatch(setIp(r));
        });
    };
}

export function fetchShop(id = null) {
    return dispatch => {
        return Api.fetchShop(id)
            .then(r => {
                if (r.is_login) {
                    dispatch(setOrderClassId(r.order_class_id));
                    dispatch(setSession(r.session));
                    if (r.is_master) {
                        browserHistory.push(LinkTo.order() + "/" + r.order_id + "/" + r.order_token);
                    } else {
                        browserHistory.push(LinkTo.shop());
                    }

                    dispatch(fetchShop());

                    return r;
                }

                dispatch(setPhotos(r.photos));
                dispatch(setFormats(r.formats));
                dispatch(setShop(r));
            })
            .catch(e => {
                console.error(e);
                if (e.status >= 400) {
                    browserHistory.push("/shop/login");
                }
            });
    };
}

export function payWithCC(payment, hash) {
    return dispatch => {
        return Api.initPayment(payment, hash);
    };
}

export function paidWithPayPal(payment, hash, details, data) {
    return dispatch => {
        return Api.paidWithPayPal(payment, hash, details, data);
    };
}

export function allowOrdering(order_id, allow) {
    return dispatch => {
        return Api.allowOrdering(order_id, allow);
    };
}

export function fetchPayment(shop_order_id, hash) {
    return dispatch => {
        return Api.fetchPayment(shop_order_id, hash).then(r => {
            dispatch(setPayment(r));
            return r;
        });
    };
}

export function setPayment(payment) {
    return {
        type: Actions.SET_PAYMENT,
        payment,
    };
}

export function muteNotifications(value) {
    return {
        type: Actions.MUTE_NOTIFICATIONS,
        value,
    };
}

export function notifyAdmin(message, type) {
    if (message) {
        toast(message, {type: type});
    }

    return {
        type: Actions.SET_ADMIN_NOTIFICATION,
        message,
        messageType: type,
    };
}

export function setMessage(message, type) {
    return {
        type: Actions.SET_MESSAGE,
        message,
        messageType: type,
    };
}

export function setRelationships(relationships) {
    return {
        type: Actions.SET_RELATIONSHIPS,
        relationships,
    };
}

export function setMethod(method) {
    return {
        type: Actions.SET_METHOD,
        method,
    };
}
export function setVoucher(voucher) {
    return {
        type: Actions.SET_VOUCHER,
        voucher,
    };
}

export function setBreadcrumb(name, value) {
    return {
        type: Actions.SET_BREADCRUMB,
        name,
        value,
    };
}
export function setBreadcrumbs(values) {
    return {
        type: Actions.SET_BREADCRUMBS,
        values,
    };
}

export function triggerDigitals() {
    return (dispatch, getState) => {
        const newValue = !getState().digitals;
        dispatch(setDigitals(newValue));
    };
}

export function setDigitals(value) {
    return {
        type: Actions.SET_DIGITALS,
        value: !!value,
    };
}

export function addShopItem(itemId, formatData, quantity = 1) {
    return {
        type: Actions.ADD_SHOP_ITEM,
        itemId,
        format_id: formatData.id,
        formatData,
        quantity,
    };
}

export function removeShopItem(itemId, formatData, quantity = -1) {
    if (quantity > 0) {
        quantity -= quantity;
    }
    return {
        type: Actions.REMOVE_SHOP_ITEM,
        itemId,
        format_id: formatData.id,
        formatData,
        quantity,
    };
}
