import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import {GOOGLE_ANALYTICS_KEY, IS_PROD} from "helpers/config";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "helpers/store";
import {Provider} from "react-redux";
//import AlertTemplate from "components/AlertTemplate";
import history from "helpers/history";
import {Router} from "react-router";
import * as Sentry from "@sentry/react";
import SuspenseLoader from "components/SuspenseLoader";
import ReactGA from "react-ga";
import {Integrations} from "@sentry/tracing";

const SENTRY_RELEASE = "skolyfoto-react@" + process.env.REACT_APP_VERSION;
Sentry.init({
    dsn: "https://c3dfc14a349a471ca94d3573bcfb7e6a@o407353.ingest.sentry.io/5276168",
    release: SENTRY_RELEASE,
    integrations: [
        new Integrations.BrowserTracing({
            // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
    ],
    beforeSend(event, hint) {
        return event;
    },
    tracesSampleRate: 0.1,
});

if (IS_PROD) {
    ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
    ReactGA.pageview(window.location.pathname); // Record a pageview for the given page

    history.listen(location => {
        ReactGA.set({page: location.pathname}); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
}

const matchAll = require("string.prototype.matchall");
matchAll.shim();

const MyFallback = () => (
    <div className="App" style={{textAlign: "center", margin: 0, position: "absolute", top: "50%", transform: "translate(-50%)", left: "50%"}}>
        <div>
            <a href="/">
                <img className="logo" src="/img/skolyfrontlogo.svg" alt="" />
            </a>
        </div>
        V aplikaci došlo k neomluvitelné nehodě. Na nápravu jsme již poslali skupinu cvičených opic. Zkuste stránku obnovit. Pokud ani to nepomůže, nebo jste ve
        spěchu, můžete nás kontaktovat na e-mail <a href={"mailto:martin@foltyn.dev"}>martin@foltyn.dev</a>. Za nepříjemnosti se omlouváme!
    </div>
);

const app = (
    <Sentry.ErrorBoundary
        fallback={MyFallback}
        showDialog
        dialogOptions={{
            title: "Jejda",
            subtitle: "Chyba byla oznámena. Pomozte nám zjistit, co se stalo.",
            helpText: "Pokud byste chtěli pomoci, popište, jak k chybě došlo.",
            labelName: "Jméno",
            labelEmail: "E-mail",
            labelComment: "Co se stalo?",
            labelClose: "Zavřít",
            labelSubmit: "Potvrdit",
            labelComments: "Co se stalo?",
            successMessage: "Zpětná vazba byla zaslána. Děkujeme!",
        }}
    >
        <Suspense fallback={<SuspenseLoader />}>
            <Provider store={store}>
                <Router history={history}>
                    <App />
                </Router>
                {/*<AlertProvider template={AlertTemplate} {...alertOptions}>
                    <Router history={history}>
                        <App />
                    </Router>
                </AlertProvider>*/}
            </Provider>
        </Suspense>
    </Sentry.ErrorBoundary>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
