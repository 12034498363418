//import {hot} from "react-hot-loader";
import AlertResponder from "components/AlertResponder";
import Loader from "components/Loader";
import {fetchPrices} from "helpers/actions";
import React from "react";
import {Route, Switch} from "react-router";
import "./App.scss";
import store from "helpers/store";
import FrontLayout from "layouts/FrontLayout";

const AdminLayout = React.lazy(() => import("./layouts/AdminLayout"));

class App extends React.Component {
    state = {
        error: null,
    };

    /*componentDidCatch(error, errorInfo) {
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({error: eventId});
        });
        this.setState({error: 1});

        return;
    }*/

    componentDidMount() {
        store.dispatch(fetchPrices());
    }

    render() {
        return (
            <div className="App">
                <Loader usereducer={true} />
                <Switch>
                    <Route path={"/admin"}>
                        <AdminLayout />
                    </Route>
                    <Route path={"/"}>
                        <FrontLayout />
                    </Route>
                </Switch>
                <AlertResponder />
            </div>
        );
    }
}

export default App;
