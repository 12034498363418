import {addFlashMessage, setAjaxLoading, setDebug, setError, setRelationships, setSuccess} from "helpers/actions";
import React from "react";
import {ROOT_API_WEB} from "helpers/config";
import history from "helpers/history";

const AxiosCommon = {
    applyRequestInterceptor(instance, setError, store) {
        instance.interceptors.request.use(
            config => {
                /** In dev, intercepts request and logs it into console for dev */
                if (typeof config.loader === "undefined" || config.loader === true) {
                    store.dispatch(setAjaxLoading(1, config.url));
                }

                const session = store.getState().session;
                if (session && session.access_token) {
                    config.headers["Authorization"] = "Bearer " + session.access_token;
                }

                return config;
            },
            error => {
                store.dispatch(setAjaxLoading(-1));

                return Promise.reject(error);
            }
        );
    },
    applyResponseInterceptor: (instance, showMessageFunction, store) => {
        // Add a response interceptor
        instance.interceptors.response.use(
            function (response) {
                console.log(response.config);
                const camel = response.data; //response.config.noCamelCase ? response.data : AxiosCommon.toCamelCase(response.data);
                if (typeof response.config.loader === "undefined" || response.config.loader === true) {
                    if (response.request.fromCache) {
                        store.dispatch(setAjaxLoading(-1));
                    } else {
                        store.dispatch(setAjaxLoading(-1, response.request.responseURL?.replace(ROOT_API_WEB, "")));
                    }
                }

                if (typeof camel.success !== "undefined" && camel.success === 0) {
                    store.dispatch(setError(camel.error || "General error"));
                    return Promise.reject(response);
                } else if (typeof camel.success !== "undefined" && camel.success === 1) {
                    if (camel.success && !camel.message) {
                        store.dispatch(setSuccess(<div>Operace provedena</div>));
                    } else {
                        store.dispatch(setSuccess(<div>{camel.message}</div>));
                    }
                } else if (camel.flashes) {
                    camel.flashes.map(flash => store.dispatch(addFlashMessage(<div>{flash.message}</div>, flash.type)));
                } else if (!camel.data) {
                    store.dispatch(setError("Chybějící odpověď serveru. Bug?"));
                    return Promise.reject(response);
                }

                if (typeof camel.relationships !== "undefined") {
                    store.dispatch(setRelationships(camel.relationships));
                }

                return camel;
            },
            function (error) {
                store.dispatch(setAjaxLoading(-1));

                if (error.response && error.response.data) {
                    if (error.response.status === 403) {
                    } else if (error.response.status === 401) {
                        if (window.location.pathname.indexOf("admin") !== -1) {
                            history.push("/admin/login");
                        } else {
                            history.push("/");
                        }
                        //window.location.href = "/";
                    }
                    // we already know from the server that user is no longer logged in
                    // just delete the local field

                    //store.dispatch(setSession(null));
                    ////window.location.reload();
                }

                if (error.response) {
                    console.debug(error.response);
                    if (typeof error.response.data === "string" && error.response.data.indexOf("<") === 0) {
                        store.dispatch(setDebug(error.response.data));
                        return Promise.reject(error.response);
                        //return Promise.reject(error.response);
                    } else {
                        //store.dispatch(setDebug(error.response));
                    }

                    if (error.response.data.errors) {
                        store.dispatch(
                            showMessageFunction(
                                <div>
                                    Chyba{error.response.status === 400 ? "" : " " + error.response.status}:<br />
                                    {Object.values(error.response.data.errors).map(errors => errors)}
                                </div>
                            )
                        );
                        return Promise.reject(error.response);
                    } else if (error.response.data.message) {
                        console.error(error.response);
                        store.dispatch(
                            showMessageFunction(
                                <div>
                                    Chyba {error.response.status}: {error.response.config.url}
                                    <br />
                                    {error.response.data.message}
                                </div>
                            )
                        );
                        return Promise.reject(error.response);
                    }
                } else {
                    console.log(error);
                    store.dispatch(showMessageFunction(<div>Chyba. Jste připojeni k internetu?</div>));
                    return Promise.reject(error);
                }

                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                return Promise.reject(error.response || error);
            }
        );
    },

    toCamelCase: obj => {
        let rtn = obj;
        if (typeof obj === "object") {
            if (obj instanceof Array) {
                rtn = obj.map(AxiosCommon.toCamelCase);
            } else if (obj === null) {
                rtn = null;
            } else {
                rtn = {};
                for (let key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        rtn[key] = AxiosCommon.toCamelCase(obj[key]);
                    }
                }
            }
        }
        return rtn;
    },
    toCamelCaseOld: obj => {
        let rtn = obj;
        if (typeof obj === "object") {
            if (obj instanceof Array) {
                rtn = obj.map(AxiosCommon.toCamelCase);
            } else if (obj === null) {
                rtn = null;
            } else {
                rtn = {};
                for (let key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        //const newKey = key.replace(/(_\w)/g, k => k[1].toUpperCase());
                        /*if (obj[key] instanceof Array || obj[key] instanceof Object) {
							rtn[newKey] = AxiosCommon.toCamelCase(obj[key]);
						} else {
							rtn[key] = AxiosCommon.toCamelCase(obj[key]);
						}*/

                        if (obj.hasOwnProperty(key)) {
                            const newKey = key.replace(/(_\w)/g, k => k[1].toUpperCase());
                            rtn[newKey] = AxiosCommon.toCamelCase(obj[key]);
                        }
                    }
                }
            }
        }
        return rtn;
    },
};
export default AxiosCommon;
