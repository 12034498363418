import isEmail from "validator/lib/isEmail";
import isPostalCode from "validator/lib/isPostalCode";
import isMobilePhone from "validator/lib/isMobilePhone";

const FormRules = {
    lengthMin: n => {
        return {
            lengthMin: ({value}) => value.length >= n,
        };
    },
    numeric: () => {
        return {
            numeric: ({value}) => !isNaN(FormRules.trim(value) * 1),
        };
    },
    typeRules: () => {
        return {
            email: ({value}) => isEmail(value),
        };
    },
    orderFormRules: () => {
        return {
            ...FormRules.defaultRules(),
        };
    },
    defaultRules() {
        return {
            type: {
                ...FormRules.typeRules(),
            },
            name: {
                name: FormRules.lengthMin(2),
                surname: FormRules.lengthMin(2),
                phone: {
                    ...FormRules.lengthMin(9),
                    phone: ({value}) => isMobilePhone(value, "cs-CZ"),
                },
                street: {
                    ...FormRules.lengthMin(5),
                    street: ({value}) => /\d/.test(value) === true,
                },
                city: FormRules.lengthMin(2),
                zip: {
                    zip: ({value}) => isPostalCode(value, "CZ"),
                },
                zipSearch: {
                    zip: ({value}) => isPostalCode(value, "CZ"),
                },
                password: {
                    ...FormRules.lengthMin(5),
                },
                voucher: {
                    lengthMin: ({value}) => value.length === 5 || value.length === 0,
                },
            },
        };
    },
    defaultMessages: () => {
        return {
            general: {
                missing: "Pole je povinné",
                invalid: "Zadaná hodnota je neplatná",
            },
            name: {
                name: {
                    rule: {
                        ...FormRules.lengthMinMessageRule(),
                    },
                },
                surname: {
                    rule: {
                        ...FormRules.lengthMinMessageRule(),
                    },
                },
                phone: {
                    rule: {
                        ...FormRules.lengthMinMessageRule(),
                        phone: "Zadané číslo je neplatné",
                    },
                },
                street: {
                    rule: {
                        ...FormRules.lengthMinMessageRule(),
                    },
                },
                city: {
                    rule: {
                        ...FormRules.lengthMinMessageRule(),
                    },
                },
                zip: {
                    rule: {
                        lengthMin: "Pole je příliš krátké",
                        zip: "Směrovací číslo je neplatné",
                    },
                },
                zipSearch: {
                    rule: {
                        lengthMin: "Pole je příliš krátké",
                        zip: "Směrovací číslo je neplatné",
                    },
                },
                password: {
                    rule: {
                        lengthMin: "Vyplněné heslo je příliš krátké (min. 5 znaků)",
                    },
                },
            },
            type: {
                email: {
                    missing: "Vyplňte e-mail",
                    invalid: "Vyplněný e-mail je neplatný",
                },
                password: {
                    missing: "Vyplňte heslo",
                    invalid: "Vyplněné heslo je neplatné",
                },
            },
        };
    },
    lengthMinMessageRule: () => {
        return {
            lengthMin: FormRules.lengthMinMessage(),
        };
    },
    lengthMinMessage: () => {
        return "Pole je příliš krátké";
    },

    adminLoginFormRules() {
        return {
            type: {
                email: ({value}) => isEmail(value),
                password: {
                    ...FormRules.lengthMin(7),
                },
            },
        };
    },
    adminLoginFormMessages() {
        return FormRules.defaultMessages();
    },

    defaultOrderFormRules() {
        return undefined;
    },
};

export default FormRules;
